import { useRouter } from 'next/router';

import type { Robot } from '@sb/remote-control/types';
import { Button } from '@sb/ui/components';
import type { IconProps } from '@sb/ui/icons';
import { InfoIcon, RobotIcon, RoutineIcon } from '@sb/ui/icons';
import { useFeatureFlag } from '@sbrc/hooks';
import { LINK_TO_HELP_LIST_PAGE, LINK_TO_ROUTINE_LIST_PAGE } from '@sbrc/utils';

import styles from './WelcomeView.module.css';

interface WelcomeViewProps {
  robot: Robot.ConvertedResponse;
}

interface WelcomeViewLinkProps {
  name: string;
  icon: (props: IconProps) => JSX.Element;
  url: string;
}

export default function WelcomeView({ robot }: WelcomeViewProps) {
  const welcomeGuides = useFeatureFlag('welcomeGuides');

  const { push } = useRouter();
  const { id } = robot;

  const title = "You're ready to get started!";

  const links: WelcomeViewLinkProps[] = [
    {
      name: 'Try moving the robot',
      icon: RobotIcon,
      url: `/robots/${id}/move`,
    },
    {
      name: 'Make your first routine',
      icon: RoutineIcon,
      url: LINK_TO_ROUTINE_LIST_PAGE,
    },
  ];

  if (welcomeGuides) {
    links.push({
      name: 'Read guides',
      icon: InfoIcon,
      url: LINK_TO_HELP_LIST_PAGE,
    });
  }

  const welcomeViewLink = ({ name, icon, url }: WelcomeViewLinkProps) => (
    <div className={styles.linkContent}>
      <Button
        onClick={() => {
          push(url);
        }}
      >
        <div className={styles.vstack}>
          <div className={styles.linkIconWrapper}>{icon({})}</div>
          <h1>{name}</h1>
        </div>
      </Button>
    </div>
  );

  return (
    <div className={styles.welcomeView}>
      <div className={styles.container}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.links}>
          {Object.entries(links).map(([_, props]) => welcomeViewLink(props))}
        </div>
      </div>
    </div>
  );
}
